.expandButton:focus,
.expandButton:active{
  box-shadow: none !important;
  outline: 0 !important;
}

.st0{fill:#FFC808;}
.st1{fill:#F58B1F;}
.st2{fill:#F99D1C;}
.st3{fill:#EF3B24;}
.st4{fill:#F26922;}
.st5{fill:#7FC042;}
.st6{fill:#C6D22D;}
.st7{fill:#E9E015;}