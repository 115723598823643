// Custom theme colors
$theme-colors: (
        "dation"    : #0376bc,
        "dation-alt": #FFB200,
        "transparent-blue": rgba(29, 119, 255, 0.48),
        "transparent-red": rgba(255, 0, 0, 0.6)
);

@import '../../node_modules/bootstrap/scss/bootstrap';

.breadcrumb-item + .breadcrumb-item::before {
  content: "";
}

.breadcrumb {
  display: flex;
  justify-content: space-around;
}

// Custom font
@font-face {
  font-family: 'cairoregular';
  src:  url('fonts/cairo-regular-webfont.woff2') format('woff2'),
        url('fonts/cairo-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'nunito_sansregular';
  src:  url('fonts/nunitosans-regular-webfont.woff2') format('woff2'),
        url('fonts/nunitosans-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: nunito_sansregular, sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: cairoregular, serif;
  font-weight: 600;
}



// Fixing vertical centering
#mainPage, #SuggestionsPage, #OrderPage, #CalendarPage {
  display: flex;
}

#GetCurrentLocationButton {
  color: #007bff;
}

.opacity_show_animation {
  animation: show_animation 250ms ease-in-out;
}

@mixin Opacity($value){
  $IEValue: $value*100;
  opacity: $value;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity="+$IEValue+")";
  filter: alpha(opacity=$IEValue);
}

@keyframes show_animation {
  0% {
    @include Opacity(0);
  }
  100% {
    @include Opacity(1);
  }
}

.arrow {
  transition: transform 200ms ease-in-out;
}

.rotate {
  transform: rotate(180deg);
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Popup styling */
@keyframes anvil {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}
.popup-content {
  margin: auto;
  background: #e9ecef;
  width: 100%;
  padding: 0.5rem;
  -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
  border-radius: 0.6rem;
  color: black;
  //border-style: solid;
  //border-width: 2px;
  //border-color: map-get($theme-colors, "dation-alt");
}
.popup-arrow {
  color: white;
  stroke: map-get($theme-colors, "dation-alt");
  stroke-width: 2px;
}
[role='tooltip'].popup-content {
  width: 35rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;

}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}
[data-popup='tooltip'].popup-overlay {
  background: transparent;
}

.popup-wrapper {
  width: 100%;
}