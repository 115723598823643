.loader {
  //height: 100vh;
  display: flex;
  margin-top:200px;
  justify-content: center;
  transition: opacity 250ms ease-in-out;
}

.visibly_hidden {
  opacity: 0%;
}

.car {
  &__body {
    animation: shake 0.2s ease-in-out infinite alternate;
    stroke: #0376BC;
  }

  &__wheel--right {
    stroke: rgba(68, 114, 196, 0.6);
  }
  &__wheel--left {
    stroke: rgba(68, 114, 196, 0.6);
  }


  &__line {
    transform-origin: right center;
    stroke-dasharray: 22;
    animation: line 0.8s ease-in-out infinite;
    animation-fill-mode: both;
    stroke: rgba(68, 114, 196, 0.6);

    &--top {
      animation-delay: 0s;
    }

    &--middle {
      animation-delay: 0.2s;
    }

    &--bottom {
      animation-delay: 0.4s;
    }
  }
}

@keyframes shake {
  0% {
    transform: translateY(-1%);
  }
  100% {
    transform: translateY(3%);
  }
}

@keyframes line {
  0% {
    stroke-dashoffset: 22;
  }

  25% {
    stroke-dashoffset: 22;
  }

  50% {
    stroke-dashoffset: 0;
  }

  51% {
    stroke-dashoffset: 0;
  }

  80% {
    stroke-dashoffset: -22;
  }

  100% {
    stroke-dashoffset: -22;
  }
}