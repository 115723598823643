.jumbotron {
    border-radius: 25px;
}

ul {
    list-style: none;
    padding-left: 0;
}

ol {
    padding-left: 1em;
}

ol li {
    position: relative;
    padding-left: 0.5em;
}

ul li {
    position: relative;
    padding-left: 1.5em;
}

ul li:before {
    content: '✓';
}

